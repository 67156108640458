import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IPathConstants {
    SupplierList(): string;
    SupplierDetail(id: string): string;
    SupplierDetail(): string;
    SupplierToPurchase(id: string): string;
    SupplierToPurchase(): string;
    PurchaseOrderList(): string;
    PurchaseOrderDetail(id: number): string;
    PurchaseOrderDetail(): string;
    InboundForecastGroupList(): string;
    InboundForecastGroupDetail(purchaseOrderReference: number): string;
    InboundForecastGroupDetail(): string;
    Inventory(): string;
    InventoryDetail(): string;
    InventoryDetail(sku: string): string;
    SalesOrderList(): string;
    SalesOrderDetail(mageReference: string): string;
    SalesOrderDetail(): string;
    RmaList(): string;
    RmaDetail(returnId: number): string;
    RmaDetail(): string;
    MontaRateInfo(): string;
}

export const PathConstants: IPathConstants =
{
    SupplierList: () => `/suppliers`,
    SupplierDetail: (id?: string) => id
        ? `/suppliers/${id}`
        : `/suppliers/:id`,
    SupplierToPurchase: (id?: string) => id
        ? `/suppliers/${id}/to-purchase`
        : `/suppliers/:id/to-purchase`,
    PurchaseOrderList: () => `/purchase-orders`,
    PurchaseOrderDetail: (id?: number) => id
        ? `/purchase-orders/${id}`
        : `/purchase-orders/:id`,
    InboundForecastGroupList: () => `/inbound-forecasts`,
    InboundForecastGroupDetail: (purchaseOrderReference?: number) => purchaseOrderReference
        ? `/inbound-forecasts/${purchaseOrderReference}`
        : `/inbound-forecasts/:purchaseOrderReference`,
    Inventory: () => `/inventory`,
    InventoryDetail: (sku?: string) => sku
        ? `/inventory/${sku}`
        : `/inventory/:sku`,
    SalesOrderList: () => `/sales-orders`,
    SalesOrderDetail: (mageReference?: string) => mageReference
        ? `/sales-orders/${mageReference}`
        : `/sales-orders/:mageReference`,
    RmaList: () => `/rmas`,
    RmaDetail: (returnId?: number) => returnId
        ? `/rmas/${returnId}`
        : `/rmas/:returnId`,
    MontaRateInfo: () => `/monta-rate-info`,
}

export const useClickableNavigate = () => {
    const [isSelectingText, setIsSelectingText] = useState(false);
    const navigate = useNavigate(); // Hook to navigate

    const onMouseDownHandler = () => {
        setIsSelectingText(false); // Reset on mouse down
    };

    const onMouseMoveHandler = () => {
        setIsSelectingText(true); // Detect movement (text selection)
    };

    function clickNavigate(e: React.MouseEvent, path: string) {
        if (e) e.stopPropagation();

        if (e.metaKey || e.ctrlKey) {
            const win = window.open(path, "_blank");
            win?.focus();
        } else {
            navigate(path);
        }
    }

    const navigateToSku = (e: React.MouseEvent, sku: string) => {
        if (!isSelectingText) {
            clickNavigate(e, PathConstants.InventoryDetail(sku));
        }
    };

    const navigateToSalesOrder = (e: React.MouseEvent, mageReferenceOrWebshopOrderId: string) => {
        if (!isSelectingText) {
            clickNavigate(e, PathConstants.SalesOrderDetail(mageReferenceOrWebshopOrderId.split('-')[0]));
        }
    };

    const navigateToPurchaseOrder = (e: React.MouseEvent, purchaseOrderNumber: number) => {
        if (!isSelectingText) {
            clickNavigate(e, PathConstants.PurchaseOrderDetail(purchaseOrderNumber));
        }
    };

    const navigateToInboundForecastGroup = (e: React.MouseEvent, purchaseOrderReference: number) => {
        if (!isSelectingText) {
            clickNavigate(e, PathConstants.InboundForecastGroupDetail(purchaseOrderReference));
        }
    };

    const navigateToReturn = (e: React.MouseEvent, returnId: number) => {
        if (!isSelectingText) {
            clickNavigate(e, PathConstants.RmaDetail(returnId));
        }
    }

    const navigateToSupplier = (e: React.MouseEvent, supplierMontaCode: string) => {
        if (!isSelectingText) {
            clickNavigate(e, PathConstants.SupplierDetail(supplierMontaCode));
        }
    }

    return {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSku,
        navigateToSalesOrder,
        navigateToPurchaseOrder,
        navigateToInboundForecastGroup,
        navigateToReturn,
        navigateToSupplier,
    };
};
