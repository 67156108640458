import {
  createBrowserRouter,
} from "react-router-dom";

// All the routes of our application
import App from "./App";
import ErrorPage from "./Pages/ErrorPage";
import Suppliers from "./Pages/SupplierList";
import SupplierDetail from "./Pages/SupplierDetail";
import SupplierToPurchase from "Pages/SupplierToPurchase";
import InboundForecastList from "Pages/InboundForecastGroupList";
import ProtectedRoute from "Components/ProtectedRoute";
import InboundForecastDetail from "Pages/InboundForecastGroupDetail";
import SalesOrderList from "Pages/SalesOrderList";
import SalesOrderDetail from "Pages/SalesOrderDetail";
import { Permissions } from "Contexts";
import { Layout } from "Components";
import MontaRateInfo from "Pages/MontaRateInfo";
import PurchaseOrderList from "Pages/PurchaseOrderList";
import PurchaseOrderDetail from "Pages/PurchaseOrderDetail";
import NotFoundPage from "Pages/NotFoundPage";
import InventoryList from "Pages/InventoryList";
import RmaList from "Pages/RmaList";
import RmaDetail from "Pages/RmaDetail";
import { PathConstants } from "utils/PathConstants";
import InventoryDetail from "Pages/InventoryDetail";

const router = createBrowserRouter([{
  element: <Layout />,
  children: [
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
    },

    {
      path: PathConstants.SupplierList(),
      element: <ProtectedRoute children={Suppliers} permissions={[Permissions.READ_SUPPLIERS]} />,
    },
    {
      path: PathConstants.SupplierDetail(),
      element: <ProtectedRoute children={SupplierDetail} permissions={[Permissions.READ_SUPPLIERS]} />,
    },
    {
      path: PathConstants.SupplierToPurchase(),
      element: <ProtectedRoute children={SupplierToPurchase} permissions={[Permissions.READ_SUPPLIERS]} />,
    },
    {
      path: PathConstants.PurchaseOrderList(),
      element: <ProtectedRoute children={PurchaseOrderList} permissions={[Permissions.READ_PURCHASE_ORDERS]} />,
    },
    {
      path: PathConstants.PurchaseOrderDetail(),
      element: <ProtectedRoute children={PurchaseOrderDetail} permissions={[Permissions.READ_PURCHASE_ORDERS]} />,
    },
    {
      path: PathConstants.InboundForecastGroupList(),
      element: <ProtectedRoute children={InboundForecastList} permissions={[Permissions.READ_INBOUND_FORECASTS]} />,
    },
    {
      path: PathConstants.InboundForecastGroupDetail(),
      element: <ProtectedRoute children={InboundForecastDetail} permissions={[Permissions.READ_INBOUND_FORECASTS]} />,
    },
    {
      path: PathConstants.Inventory(),
      element: <ProtectedRoute children={InventoryList} permissions={[Permissions.READ_STOCKS]} />,
    },
    {
      path: PathConstants.InventoryDetail(),
      element: <ProtectedRoute children={InventoryDetail} permissions={[Permissions.READ_STOCKS]} />,
    },
    {
      path: PathConstants.SalesOrderList(),
      element: <ProtectedRoute children={SalesOrderList} permissions={[Permissions.READ_SALES_ORDERS]} />,
    },
    {
      path: PathConstants.SalesOrderDetail(),
      element: <ProtectedRoute children={SalesOrderDetail} permissions={[Permissions.READ_SALES_ORDERS]} />,
    },
    {
      path: PathConstants.RmaList(),
      element: <ProtectedRoute children={RmaList} permissions={[Permissions.READ_RMAS]} />,
    },
    {
      path: PathConstants.RmaDetail(),
      element: <ProtectedRoute children={RmaDetail} permissions={[Permissions.READ_RMAS]} />,
    },
    {
      path: PathConstants.MontaRateInfo(),
      element: <ProtectedRoute children={MontaRateInfo} permissions={[]} />,
    },

    {
      path: "*",
      element: <NotFoundPage />,
    }
  ]
}]);


export default router;
