import moment from "moment";
import { MontaReturn } from "types/rma";
import { Table, Tbody, Td, Th, Thead, Tr } from "Components/Library/Table";
import { useClickableNavigate } from "utils/PathConstants";

export default function RmaTable({ rmas }: { rmas: MontaReturn[] }) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToReturn
    } = useClickableNavigate();

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Order #</Th>
                        <Th>Return Id</Th>
                        <Th>Email</Th>
                        <Th>All Accepted</Th>
                        <Th className="text-right">Lines</Th>
                        <Th className="text-right">Registered At</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rmas.length === 0 && (
                        <Tr>
                            <Td colSpan={6}>No RMAs found</Td>
                        </Tr>
                    )}
                    {rmas.map((rma, index) => (
                        <Tr
                            key={index}
                            className="cursor-pointer hover:bg-gray-200"
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                            onClick={(e) => navigateToReturn(e, rma.returnId)}
                        >
                            <Td>{rma.reference.mage}</Td>
                            <Td>{rma.returnId}</Td>
                            <Td>{rma.customerEmail}</Td>
                            <Td>{rma.lines.every(line => line.isSellable) ? 'Yes' : 'No'}</Td>
                            <Td className="text-right">{rma.lines.length}</Td>
                            <Td className="text-right">{moment(rma.registeredAt).format('DD-MM-YYYY HH:mm')}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
