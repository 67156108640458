import { useEffect, useRef, useState } from "react";
import { useApiClient } from 'Contexts/ApiClientContext';
import { Pagination } from "utils/apiClient";
import { Loading, PageHeader, Pager, Select, TextInput, Button } from 'Components';
import { isEmpty } from "utils";
import { useDebounce } from 'use-debounce';
import { SupplierAsOption } from "types/supplier";
import { Stock, StockSearchCriteria } from "types/stock";
import StockTable from "Components/Inventory/StockTable";
import Modal from "Components/Library/Modal";
import { read, utils } from 'xlsx';
import { IoClose } from "react-icons/io5";

interface SearchCriteria {
    searchQueryGeneral: string;
    searchQuerySkuOrEan: string
    supplierMontaCode: string | null,
}

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 30;

export default function InventoryList() {
    const apiClient = useApiClient();
    const [supplierOptions, setSupplierOptions] = useState<SupplierAsOption[]>([]);

    // Retrieve initial search parameters from sessionStorage or default values
    const initialSearchParams = (): SearchCriteria => {
        const storedParams = sessionStorage.getItem('searchParamsInventory');
        return storedParams ? JSON.parse(storedParams) : {
            searchQueryGeneral: "",
            searchQuerySkuOrEan: "",
            supplierMontaCode: null,
        };
    };
    const [searchParams, setSearchParams] = useState<SearchCriteria>(initialSearchParams);
    const [debouncedSearchParams] = useDebounce(searchParams, 300);
    const [isFetchingStocks, setIsFetchingStocks] = useState(true);

    const [stocks, setStocks] = useState(null as Stock[] | null)
    const [pagination, setPagination] = useState<Pagination | null>(null)
    const [syncImportModalOpen, setSyncImportModalOpen] = useState(false);
    const [parsedSyncImportRows, setParsedSyncImportRows] = useState<string[]>([]);
    const [syncImportError, setSyncImportError] = useState<string>();

    const previousSearchCriteria = useRef<StockSearchCriteria | null>(null);
    const handleSearchParamChange = (param: keyof SearchCriteria, value: any) => {
        setSearchParams(prevState => ({
            ...prevState,
            [param]: value
        }));
    };
    const handleClearSearchParams = () => {
        sessionStorage.removeItem('searchParamsInventory');
        setSearchParams(initialSearchParams);
    }

    // Store searchParams in sessionStorage whenever they change
    useEffect(() => {
        sessionStorage.setItem('searchParamsInventory', JSON.stringify(debouncedSearchParams));
    }, [debouncedSearchParams]);

    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const searchCriteria: StockSearchCriteria = {
                    criteria: isEmpty(debouncedSearchParams.searchQueryGeneral)
                        ? null : debouncedSearchParams.searchQueryGeneral,
                    skuOrEan: isEmpty(debouncedSearchParams.searchQuerySkuOrEan)
                        ? null : debouncedSearchParams.searchQuerySkuOrEan,
                    supplierMontaCode: isEmpty(searchParams.supplierMontaCode)
                        ? null : searchParams.supplierMontaCode,
                    page: pagination?.page ?? DEFAULT_PAGE,
                }
                if (searchCriteria.criteria === previousSearchCriteria.current?.criteria
                    && searchCriteria.skuOrEan === previousSearchCriteria.current?.skuOrEan
                    && searchCriteria.supplierMontaCode === previousSearchCriteria.current?.supplierMontaCode
                    && searchCriteria.page === previousSearchCriteria.current?.page) {
                    // No need to fetch again if the search criteria hasn't changed.
                    return;
                }
                previousSearchCriteria.current = searchCriteria;

                setIsFetchingStocks(true);
                const { data } = await apiClient.getStocks(
                    searchCriteria,
                    pagination?.page ?? DEFAULT_PAGE,
                    pagination?.pageSize ?? DEFAULT_PAGE_SIZE
                );
                if (data) {
                    setStocks(data.data);
                    setPagination(data.pagination);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
            } finally {
                setIsFetchingStocks(false);
            }
        };

        fetchStocks();
    }, [apiClient, pagination?.page, pagination?.pageSize, debouncedSearchParams, searchParams.supplierMontaCode]);

    useEffect(() => {
        const fetchSupplierOptions = async () => {
            try {
                const { data } = await apiClient.getSuppliersAsOptions();

                if (data) {
                    setSupplierOptions(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers as options:", error);
            }
        };

        fetchSupplierOptions();
    }, [apiClient]);

    const handleNextPageClick = () => {
        if (!pagination?.hasNextPage) return;
        setPagination({ ...pagination, page: pagination?.page + 1 } as Pagination);
    };
    const handlePreviousPageClick = () => {
        if (!pagination?.hasPreviousPage) return;
        setPagination({ ...pagination, page: pagination.page - 1 } as Pagination);
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setParsedSyncImportRows([]);
        setSyncImportError('');
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            const parsedRows = [];
            const data = await file.arrayBuffer();
            const workbook = read(data);
            const sheet = workbook.SheetNames.includes('Sheet1') ? workbook.Sheets['Sheet1'] : workbook.Sheets[workbook.SheetNames[0]];
            const rows: Record<string, string>[] = utils.sheet_to_json(sheet);

            for (const row of rows) {
                for (const key in row) {
                    if (key.toLowerCase() === 'sku') {
                        var sku = row[key];

                        // If the SKU is a number, we convert it to a string to process it uniformly with string SKUs.
                        if (typeof sku === 'number') {
                            sku = (sku as number).toString();
                        }
                        // If the SKU is a string and not empty, we process it.
                        if (typeof sku === 'string' && sku.length > 0) {
                            parsedRows.push(sku);
                        }
                        // Note: Date and other types are ignored.
                    }
                }
            }
            setParsedSyncImportRows(parsedRows)

            if (parsedRows.length === 0) {
                setSyncImportError('No valid Skus found in the file.');
            }
        } catch (error: any) {
            console.error("Error parsing file:", error);
        }
    }

    const submitSyncImport = async () => {
        try {
            await apiClient.pollMontaStocks(parsedSyncImportRows);
            setSyncImportModalOpen(false);
            setParsedSyncImportRows([]);
        } catch (error) {
            console.error("Error importing stocks:", error);
        }
    }

    const supplierOptionList = () => {
        const sortedList = supplierOptions
            .map(supplier => ({
                value: supplier.montaCode,
                label: supplier.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        sortedList.unshift({ value: "", label: "All Suppliers" });

        return sortedList;
    }


    if (stocks === null) return (
        <Loading />
    );

    return (
        <>
            <PageHeader
                title="Inventory"
                toolbar={(
                    <Button
                        className="mt-1"
                        onClick={() => setSyncImportModalOpen(true)}
                        type="cta"
                    >
                        Poll with import
                    </Button>
                )}
            />

            <div className="flex flex-wrap items-center justify-between">
                <div className="flex items-center mb-4 gap-x-3">
                    <TextInput
                        placeHolder="Search by SKU or EAN"
                        className="w-72"
                        value={searchParams.searchQuerySkuOrEan}
                        onChange={(newValue) => handleSearchParamChange("searchQuerySkuOrEan", newValue)}
                        withClearButton
                    />
                    <TextInput
                        placeHolder="Search by name"
                        className="w-80"
                        value={searchParams.searchQueryGeneral}
                        onChange={(newValue) => handleSearchParamChange("searchQueryGeneral", newValue)}
                        withClearButton
                    />
                    <Select
                        className="!w-72"
                        value={!searchParams.supplierMontaCode ? "All Suppliers" : searchParams.supplierMontaCode}
                        disabled={isFetchingStocks}
                        onChange={(newValue) => { handleSearchParamChange("supplierMontaCode", newValue) }}
                        options={supplierOptionList()}
                    />
                    <Button type="white" className="mt-1 w-5 h-10 shadow-none" onClick={handleClearSearchParams}>
                        <IoClose className="-my-2 -mx-2" />
                    </Button>
                </div>
                <div className="flex items-center mb-4 gap-x-3">
                    {syncImportModalOpen && (
                        <Modal
                            title="Poll products using import"
                            onClose={() => setSyncImportModalOpen(false)}
                        >
                            <>
                                <p className="mb-2">
                                    Please upload a .xlsx file containing the Skus:
                                </p>
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileUpload}
                                />
                                {syncImportError && <p className="text-red-500">{syncImportError}</p>}
                                {parsedSyncImportRows.length > 0 && (
                                    <>
                                        <div className="mt-4">
                                            <p>{parsedSyncImportRows.length} skus have been found.</p>
                                        </div>
                                        <div className="flex justify-end mt-4">
                                            <Button onClick={submitSyncImport} type="cta">
                                                Start synchronization
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </>
                        </Modal>
                    )}
                </div>
            </div>

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <StockTable stocks={stocks} suppliers={supplierOptions} />
            </div>
            {pagination && (
                <Pager
                    currentPage={pagination.page}
                    pageSize={pagination.pageSize}
                    totalItems={pagination.totalItems}
                    handleNextPageClick={handleNextPageClick}
                    handlePreviousPageClick={handlePreviousPageClick}
                />
            )}
        </>
    );
}
