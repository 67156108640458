import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CommentModel } from 'types/shared';
import { Button } from 'Components';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

type Props = {
    comments: CommentModel[]
    addComment: (comment: CommentModel) => void
    editComment: (comment: CommentModel) => void
    deleteComment: (commentId: number) => void
}

const Comments = ({ comments, addComment, editComment, deleteComment }: Props) => {
    const { user } = useAuth0(); // Get the authenticated user
    const [newComment, setNewComment] = useState('');
    const [selectedCommentEdit, setSelectedCommentEdit] = useState<CommentModel | null>(null);

    const handleAddComment = () => {
        if (!user?.email) return;

        if (newComment.trim().length === 0 || newComment.length > 250) return;
        const createdAt = new Date();

        addComment({
            id: null,
            text: newComment,
            author: user?.email,
            createdAt,
            updatedAt: createdAt,
        });
        setNewComment('');
    };

    const handleEditComment = () => {
        if (!selectedCommentEdit?.id) return;

        const newText = selectedCommentEdit.text.trim();
        if (newText.length === 0) {
            deleteComment(selectedCommentEdit.id);
            return;
        }
        if (newText.length > 250) {
            // TODO: Add error toast
            return;
        }
        editComment(selectedCommentEdit);
        setSelectedCommentEdit(null);
    };

    const handleDeleteComment = (comment: CommentModel) => {
        if (!comment?.id) return;

        deleteComment(comment.id);
    }

    const sortedComments = comments.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return (
        <div className="p-4 md:p-5 overflow-y-auto max-h-80vh w-full max-w-3xl" style={{ minWidth: '768px' }}>
            <div className="mb-4">
                <textarea
                    className="w-full p-2 text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Add a comment (max 250 characters)"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    maxLength={250}
                    rows={4}
                />
                <div className="flex justify-between items-center mt-2">
                    <span className="text-sm text-gray-500">{newComment.length}/250</span>
                    <Button
                        onClick={handleAddComment}
                        type="cta"
                    >
                        Post Comment
                    </Button>
                </div>
            </div>

            {sortedComments.length === 0 && (
                <p className="text-gray-500">
                    No comments have been placed.
                </p>
            )}

            <ol className="relative border-s border-gray-400 ms-3.5 mb-4 md:mb-5">
                {sortedComments.map((comment) => (
                    <li key={comment.id} className="ms-8 mb-10">
                        <div className="flex items-center justify-between">
                            <div>
                                <p className="font-semibold text-gray-900">{comment.author}</p>
                                <p className="text-gray-500 text-sm">
                                    {new Date(comment.createdAt).toLocaleString()}
                                    {comment.createdAt !== comment.updatedAt && ' (edited)'}
                                </p>
                            </div>
                            {/* Show Edit button only if user is the author */}
                            {comment.author === user?.email && (
                                selectedCommentEdit?.id !== comment.id
                                    ? (
                                        <span
                                            className="hover:text-gray-600 cursor-pointer"
                                            onClick={() => setSelectedCommentEdit(comment)}
                                        >
                                            <FaPencilAlt className="w-5 h-5" />
                                        </span>
                                    )
                                    : (
                                        <span
                                            className="hover:text-gray-600 cursor-pointer"
                                            onClick={() => handleDeleteComment(comment)}
                                        >
                                            <FaTrashAlt className="w-5 h-5" />
                                        </span>
                                    )
                            )}
                        </div>

                        {/* Show textarea if editing */}
                        {selectedCommentEdit?.id !== comment.id ? (
                            <p className="text-gray-700 mt-2">{comment.text}</p>
                        ) : (
                            <div>
                                <textarea
                                    className="w-full p-2 text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                                    value={selectedCommentEdit.text}
                                    onChange={(e) => setSelectedCommentEdit({ ...selectedCommentEdit, text: e.target.value })}
                                    maxLength={250}
                                />
                                <div className="flex justify-between items-center mt-2">
                                    <span className="text-sm text-gray-500">{selectedCommentEdit.text.length}/250</span>
                                    <div className='flex justify-end gap-x-3'>
                                        <Button
                                            onClick={() => setSelectedCommentEdit(null)}
                                            type="white"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleEditComment}
                                            type="cta"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default Comments;
