import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import moment from "moment";
import { Table, Thead, Th, Tbody, Tr, Td, Card, Loading, PageHeader, SectionTitle } from 'Components';
import { MontaReturn } from "types/rma";
import { formatToDutchDateTime } from "utils/dates";
import { useClickableNavigate } from "utils/PathConstants";

export default function RmaDetail() {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSku,
    } = useClickableNavigate();

    let { returnId } = useParams();
    const apiClient = useApiClient();

    const [rma, setRma] = useState<MontaReturn | null>(null)

    useEffect(() => {
        const fetchRma = async () => {
            if (!returnId) return;
            try {
                const { data } = await apiClient.getRmaByReturnId(returnId);
                if (data) {
                    setRma(data);
                }
            } catch (error) {
                console.error("Error fetching RMA:", error);
            }
        };

        fetchRma();
    }, [apiClient, returnId]);

    if (rma === null) return (
        <Loading />
    )

    return (
        <>
            <div className="flex justify-between items-center">
                <PageHeader
                    title={rma.returnId}
                    documentTitle={`${rma.reference.mage} | RMA`}
                    metaData={`Registered: ${moment(rma.registeredAt).format('DD-MM-YYYY HH:mm')}`}
                />
            </div>

            <div className="grid md:grid-cols-2">
                <Card className="w-96">
                    <span className="block mt-1">Magento Order
                        <span className="block text-gray-600">{rma.reference.mage}</span>
                    </span>
                    <span className="block mt-1">Email
                        <span className="block text-gray-600 break-all">{rma.customerEmail ?? "-"}</span>
                    </span>

                    <span className="block mt-1">Forecast code
                        <span className="block text-gray-600">{rma.forecastCode ?? "-"}</span>
                    </span>
                    <span className="block">Return Forecast ID
                        <span className="block text-gray-600">{rma.returnId ?? "-"}</span>
                    </span>
                </Card>
            </div>

            <SectionTitle>Returned Items</SectionTitle>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Accepted</Th>
                        <Th className="text-right">Qty</Th>
                        <Th>Name</Th>
                        <Th>Sku</Th>
                        <Th>EAN</Th>
                        <Th>Cause</Th>
                        <Th>Comment</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rma.lines.length === 0 && (
                        <Tr>
                            <Td colSpan={7}>
                                No items found
                            </Td>
                        </Tr>
                    )}
                    {rma.lines.map((line, index) => (
                        <>
                            <Tr key={index}>
                                <Td>
                                    {line.isSellable
                                        ? <span className="bg-primary text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">Yes</span>
                                        : <span className="bg-red-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">No</span>
                                    }
                                </Td>
                                <Td className="text-right">{line.returnedQuantity}</Td>
                                <Td>
                                    <span className="flex items-center">
                                        {line.product.name}
                                    </span>
                                </Td>
                                <Td>
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onMouseDown={onMouseDownHandler}
                                        onMouseMove={onMouseMoveHandler}
                                        onClick={(e) => navigateToSku(e, line.product.sku)}
                                    >
                                        {line.product.sku}
                                    </span>
                                </Td>
                                <Td>{line.product.ean ?? "-"}</Td>
                                <Td>{line.cause}</Td>
                                <Td>{line.comment ?? "-"}</Td>
                            </Tr>
                        </>
                    ))}
                </Tbody>
            </Table>

            <SectionTitle>Refund events</SectionTitle>
            <Table>
                <Thead>
                    <Tr>
                        <Th className="text-right w-8"># Lines</Th>
                        <Th>Items</Th>
                        <Th>Occured At</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rma.profortoData.refundEvents.length === 0 && (
                        <Tr>
                            <Td colSpan={3}>
                                No refund events found
                            </Td>
                        </Tr>
                    )}
                    {rma.profortoData.refundEvents.map((line, index) => (
                        <Tr key={index}>
                            <Td className="text-right w-8">{line.refundItems.length}</Td>
                            <Td>
                                {line.refundItems.map(refundItem =>
                                    `${refundItem.returnedQuantity}x ${refundItem.sku}`
                                ).join(", ")}
                            </Td>
                            <Td>{formatToDutchDateTime(line.occuredAt)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
